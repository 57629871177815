.layout {
  padding: 10px;
  border: 1px solid #fcfdfd;
  border-radius: 4px;
}

.selected {
  background: #fcfdfd;
}

.placeholder {
  height: 100px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  border: 1px dashed white;
  background-color: rgba($color: #ffffff, $alpha: 0.4);
  p {
    font-style: italic;
    color: gray;
    margin: auto;
  }
}
