:global {
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-content {
    border-radius: 4px !important;
  }
}

.fullModal {
  :global {
    .ant-modal-content {
      height: calc(100% - 50px);
      position: fixed;
      width: 100%;
      left: 0px;
      top: 50px;
    }
  }
}

// .dimmer {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1111111111111111111111111111111;
//   background-color: rgba(0, 0, 0, 0.5);
//   height: 100%;
//   width: 100%;
// }

// .content {
//   position: absolute;
//   top: 50px;
//   border-radius: 10px 0px;
//   background: #ffffff;
//   height: 100%;
//   width: 100%;
//   // padding: 1.5rem;
// }
