// .titleContainer {
//   width: 17rem;
// }

// .content {
//   width: calc(100% - 17rem);
// }

// .pageContent {
//   max-width: 1200px;
//   width: 100%;
//   margin: auto;
// }

.fragmentMargin {
  max-width: 1000px;
  width: 100%;
  margin: auto;
}
