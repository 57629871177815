.color {
  width: 28px;
  height: 28px;
  border-radius: 100%;
}

.colorContainer {
  padding: 2px;
}

.selectedColor {
  border: 1px solid rgba(49, 210, 210, 0.6);
}

.input {
  border: 1px solid #e6f0f3;
  padding: 0.5rem 0;
  border-radius: 4px;
  margin-top: -30%;
  box-shadow: 0px 0px 1px 0.1px rgba($color: #000000, $alpha: 0.2);
  position: absolute;
  background-color: #ffffff;
  width: 50%;
  z-index: 2;
  margin-bottom: 30px;
}

.hidden {
  display: none;
}

.dimmer {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
