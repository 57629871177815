.button {
  cursor: pointer;
  padding: 10px;
  &:hover {
    background: #f2f6f7;
    border-radius: 5px;
  }
}

:global {
  .ant-popover {
    padding-top: 0px !important;
  }
  .ant-popover-inner {
    min-width: 130px;
  }
  .ant-popover-open {
    .button {
      background: #f2f6f7;
      border-radius: 5px;
    }
  }
}
