.container {
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: fit-content;
  transition: width 1s, padding-right 0.2s;
  cursor: pointer;
  height: 19px;
  width: 19px;

  .content {
    display: none;
  }

  &:hover {
    padding-right: 10px;
    width: unset !important;
    .content {
      display: unset !important;
    }
  }
}

.danger {
  background: #e94540;
}

.dangerText {
  color: #ffffff;
  font-weight: 700;
}

.success {
  background: #88e54c;
}

.successText {
  color: #ffffff;
  font-weight: 700;
}
