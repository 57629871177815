.container {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid #e6f0f3;
  // padding: 10px;
  border-radius: 4px;
  top: 0px;
  left: 0px;
  display: none;
  background: #fff;
  z-index: 2;

  :global {
    .ant-upload-picture-card-wrapper {
      display: flex;
    }
  }
}

.header {
  // padding: 10px;
  border-bottom: 1px solid #f0f1f1;
}

.active {
  display: block !important;
}

.dimmer {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.removeImage {
  position: absolute;
  // right: -12px;
  margin-left: 19px;
  border-radius: 100%;
  &:hover {
    box-shadow: 0px 0px 10px 0.1px rgba($color: #000000, $alpha: 0.2);
  }
}

.avatarContainer {
  width: max-content;
  // &:hover {
  //   .container {
  //     display: block;
  //   }
  // }
}

.image {
  transition: height 1s;
  height: 160px;
}

.default {
  display: flex;
  align-items: center;
  text-align: center;

  :global {
    .ant-upload-picture-card-wrapper {
      margin-top: 95px;
      // width: 160px;
      // position: absolute !important;
      width: 160px;
      position: sticky;
      width: 100%;
      margin-left: -100%;
    }
  }

  .image {
    height: 60px;
  }
  // &:hover {
  //   .image {
  //     height: 60px;
  //   }
  // }
}

.uploadContainer {
  position: absolute !important;
}

.uploadPhoto {
  font-weight: 500;
  color: #46b0c6;
  font-size: 13px;
  // opacity: 0;
  transition: opacity 0.2s;
}

.buttons {
  // position: absolute;
  display: inline-grid;
  padding: 20px;
  // width: 160px;
  visibility: hidden;
  width: 160px;
  position: sticky;
  width: 100%;
  margin-left: -100%;
}

.change {
  color: #ffffff;
}

.select {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: #ffffff;
}

.uploadIcon {
  margin-top: 12px;
  width: 30px;
  height: 30px;
  color: #ffffff;
  border-radius: 4px;
  border: 1px dashed #46b0c6;
}

.selectedIcon {
  border-radius: 4px;
  border: 1px solid #bde3eb;
  width: 40px;
  height: 40px;
  text-align: center;
}

.pencil {
  font-size: 12px;
  margin: auto;
}

.pencilContainer {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(16, 45, 51, 0.08);
  height: 28px;
  width: 28px;
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  // right: 10px;
  margin-left: 50px;
  margin-top: -20px;
  z-index: 1;
}
