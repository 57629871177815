:global {
  .ant-skeleton,
  .ant-skeleton-button {
    // width: 80% !important;
  }
}

.container {
  min-width: 100px !important;
  min-height: 20px !important;
  margin-bottom: 5px;
}

.content {
  width: 100% !important;
}

.small {
  min-width: 300px !important;
  height: 17px !important;
  margin-bottom: 2px;
}

.medium {
  min-width: 500px !important;
  height: 17px !important;
  margin-bottom: 10px;
}
