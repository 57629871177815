.headerContainer {
  background: #fcfdfd;
  height: 520px;

  .sub {
    width: 480px;
    font-size: 18px;
  }
}

.imageHeader {
  max-width: unset !important;
}

.header {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  height: 100%;
}

.h1 {
  width: 500px;
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 10px;
}

.apis {
  padding-top: 100px;
  padding-bottom: 100px;
}

.apiContent {
  height: calc(100% - 50px);
}

.apisButton {
  height: 70px;
}

.smallTitle {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3494a8;
  font-size: 14px;
  font-weight: 600;
}

.iconImage {
  // margin: 20px auto;
  height: 160px;
  display: flex;
  align-items: center;
  .image {
    margin: auto;
  }
}

.support {
  .supportText {
    width: 600px;
  }

  .supportTitle {
    font-weight: bold;
    font-size: 38px;
    line-height: 46px;
  }
  background: #e4f3f7;
  height: 360px;

  .bg {
    height: 514px;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
  }
  .icon {
    margin: auto;
    // width: 614px;
    // width: 1100px;
    overflow: visible !important;
  }
}

.discord {
  background-color: #5769ea;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 26px;
  &:hover,
  &:active {
    background-color: #5769ea;
    opacity: 0.9;
  }
}

.createApp {
  height: 376px;
  background: #3335cf;
}

.subTitle {
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  min-width: 500px;
}
