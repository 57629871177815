.container {
  width: 100%;
  padding-left: 200px;
}

.sideNav {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 200px;
  background: #ffffff;
  padding: 100px 0;
  box-shadow: 0px 0px 10px 5px rgba($color: #000000, $alpha: 0.01);
}

.header {
  display: flex;
  align-items: center;
  transition: left 1s, bottom 1s, position 1s;
  width: 100%;
  // .info {
  //   margin-left: 1rem;
  // }
}

.backButton {
  padding: 0 1rem;
}

.navItem {
  padding: 0.5rem 1rem;
  &:not(:first-child) {
    border-top: 1px solid #f2f6f7;
  }
}

.headerAttached {
  position: fixed;
  height: 90px;
  box-shadow: 0px 0px 2px 0.1px rgba($color: #000000, $alpha: 0.2);
  background: white;
  z-index: 1;
  top: 65px;
  right: 20px;
  padding: 10px;
  border-radius: 4px;
}

.hey {
  height: 1000px;
}
.content {
  max-width: 700px;
  .code {
    border: 1px dashed #f2f6f7;
    border-radius: 4px;
    background: #ffffff;
  }
}

.input {
  border: 0px;
  font-family: monospace;
  transition: background 1s;
  background: #f2f6f7;
}

.copy {
  color: #9b9ca5;

  font-size: 12px;
}

.actions {
  display: flex;
  margin-left: 5px;
  margin-top: -5px;
}
