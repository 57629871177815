.content {
  height: calc(100% - 60px);
  min-width: 250px;
}

.child {
  min-height: calc(100% - 30px);
}

.layoutAContainer {
  margin-top: 60px;
}
